

import CheckIcon from '@mui/icons-material/Check';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import SlowMotionVideoRoundedIcon from '@mui/icons-material/SlowMotionVideoRounded';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';

import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect,useContext } from 'react';
import {CtxTodoItems,CtxTodoid,CtxPriority,CtxStatus} from './TodoStates.jsx';






function EditDivision(props){
  const [btnPriority, setBtnPriority] = useState('');
  const [btnStatus, setBtnStatus] = useState('');

  const {todoItems, setTodoItems}=useContext(CtxTodoItems);
  const {todoid,setTodoid}=useContext(CtxTodoid);
  const {priority, setPriority}=useContext(CtxPriority);
  const {status, setStatus}=useContext(CtxStatus);


  const BtnPriorities = () => {
    return (
      <>
        <span className="input-group-text py-0 px-1 fs-6 m-0">
          <button id="btnHigh" value="high" className='myBtn me-1 my-0' title="Set as High" onClick={changePriority}><SquareRoundedIcon sx={{ color: 'red' }} fontSize="small" /></button>
          <button id="btnMedium" value="medium" className='myBtn me-1 my-0' title="Set as Medium" onClick={changePriority}><SquareRoundedIcon sx={{ color: 'orange' }} fontSize="small" /></button>
          <button id="btnLow" value="low" className='myBtn my-0' title="Set as Low" onClick={changePriority}><SquareRoundedIcon sx={{ color: 'lightgreen' }} fontSize="small" /></button>
        </span>
      </>
    );
  }
  
  const BtnStatuses = () => {
    return (
      <>
        <span className="input-group-text py-0 px-1">
          <button id="btnInQueue" value="In-Queue" className='myBtn me-1 my-0' title="Set as In-Queue" onClick={changeStatus}><PostAddRoundedIcon fontSize="small" /></button>
          <button id="btnInProgress" value="In-Progress" className='myBtn me-1 my-0' title="Set as In-Progress" onClick={changeStatus}><SlowMotionVideoRoundedIcon color="primary" fontSize="small" /></button>
          <button id="btnInWaiting" value="In-Waiting" className='myBtn me-1 my-0' title="Set as In-Waiting" onClick={changeStatus}><PauseCircleOutlineRoundedIcon color="warning" fontSize="small" /></button>
          <button id="btnCompleted" value="Completed" className='myBtn my-0' title="Set as Completed" onClick={changeStatus}><CheckIcon color="success" fontSize="small" /></button>
        </span>
      </>
    );
  }
  
  const handleKeyDown = (event) => {
    
    if (event.key === 'Enter' && event.ctrlKey) {
      // Prevent the default behavior (e.g., newline in textarea)
      event.preventDefault();
      SaveCard();

    }
  };

  useEffect(() => {
    let prtyColor = 'info';
    switch (priority) {
      case 'high':
        prtyColor = 'red';
        break;
      case 'medium':
        prtyColor = 'orange';
        break;
      default:
        prtyColor = 'lightgreen';
        break;
    };
    console.log(`Priority Color:${prtyColor}`);
    setBtnPriority(<SquareRoundedIcon sx={{ color: prtyColor }} fontSize="small" />);
  }, [priority]);
  
  useEffect(() => {
  
    switch (status) {
      case 'Completed':
        setBtnStatus(<CheckIcon color="success" fontSize="small" />);
        break;
      case 'In-Waiting':
        setBtnStatus(<PauseCircleOutlineRoundedIcon color="warning" fontSize="small" />);
        break;
      case 'In-Progress':
        setBtnStatus(<SlowMotionVideoRoundedIcon color="primary" fontSize="small" />);
        break;
      default:
        setBtnStatus(<PostAddRoundedIcon fontSize="small" />);
        break;
    };
  }, [status]);
  
  const changePriority = (event) => {
    let prty = event.currentTarget.value;
    console.log(`Priority:${prty}`);
    setPriority(prty);
  }

  const changeStatus = (event) => {
    let status = event.currentTarget.value;
    console.log(`Status:${status}`);
    setStatus(status);
  }
  const clearAll=()=>{
    document.getElementById("title").value='';
    document.getElementById("desc").value='';
    setTodoid(0);
    setPriority('low');
    setStatus('In-Queue');
  }




  const SaveCard= ()=>{
  
    if(document.getElementById("title").value == '' ) {
      alert("Title can not be blank");
      return false;
    }
    let ans=window.confirm("Would you like to Save this task?");
    if(!ans) return false;
    let card={};
    console.log("todoid = "+document.getElementById("todoid").value);
    if(parseInt(document.getElementById("todoid").value) <= 0) {
      card =  {
        title: document.getElementById("title").value,
        desc: document.getElementById("desc").value,
        priority: document.getElementById("priority").value,
        status: document.getElementById("status").value,
      }
      insertData(card);
    } else {
      card =  {
        id: parseInt(document.getElementById("todoid").value),
        title: document.getElementById("title").value,
        desc: document.getElementById("desc").value,
        priority: document.getElementById("priority").value,
        status: document.getElementById("status").value,
  
      }
      updateData(card);
    }
  
    console.log(card);
  
    
    clearAll();
  
  }
  
  const updateData=async (card)=> {
    console.log(JSON.stringify(card));
    try{
      const res = await fetch(`${window.config.REACT_APP_API_HOST}:${window.config.REACT_APP_API_PORT}/todo/save`,{
      method: "POST",
        headers: {
            "Accept":"application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(card)
      }
    );
  
      const resCard = await res.json();
      
      setTodoItems((oldItems)=>{
        let items=[];
        oldItems.map((item)=>{
          if(item.id == card.id) items = [... items, card];
          else items = [... items, item];
        })
        return (items);
        
      })
      
    } catch(e){
      console.log(e);
    }
  
  }
  
  
  const insertData=async (card)=> {
    console.log(JSON.stringify(card));
    try{
      const res = await fetch(`${window.config.REACT_APP_API_HOST}:${window.config.REACT_APP_API_PORT}/todo/save`,{
      method: "POST",
      //mode: 'no-cors',
        headers: {
            "Accept":"application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(card)
      }
    );
  
      const resCard = await res.json();
  
      setTodoItems((oldItems)=> {
        return [...oldItems,resCard]
      });
    } catch(e){
      console.log(e);
    }
  
  }
  
return (
  
<div id="right_panel" className="d-sm-flex flex-column flex-grow-1">

<div className="d-sm-flex flex-row flex-wrap justify-content-between mt-1">
  <div className="d-sm-flex me-1">
    <button className="myBtn px-0 py-0" style={{pointerEvents: 'none',height:20+'px',padding:0+'px',margin:0+'px'}}>
      <font size="2">Priority: &nbsp;{btnPriority} &nbsp;{priority}</font>
      <input id="priority" type="hidden" value={priority}/>
    </button>
  </div>
  <div className="d-sm-flex ">
    
    <button className="myBtn px-0 py-0" style={{pointerEvents: 'none',height:25+'px',padding:0+'px',margin:0+'px'}}>
    <font size="2">Status: &nbsp;{btnStatus} &nbsp;{status} </font>
    <input id="status" type="hidden" value={status}/>
    </button>
  </div>
</div>
<div className="d-sm-flex">
  <div className="d-sm-flex flex-grow-1 mt-1 me-1">
    <input id="title" type="text" className="form-control" placeholder="Title" onKeyDown={handleKeyDown} />
  </div>
  <div className="d-sm-flex mt-1">
    <input id="todoid" type="hidden" value={todoid} />
  </div>
</div>
<div className="d-sm-flex flex-grow-1 mt-1">
  <textarea id="desc" className="form-control" maxLength="2000" placeholder='Description [upto 2000 chars]' onKeyDown={handleKeyDown}></textarea>
</div>
<div className="d-sm-flex flex-row flex-wrap mt-1">

  <div className="d-sm-flex">
    <BtnStatuses />
  </div>
  <div className="d-sm-flex">
    <BtnPriorities />
  </div>
  <div className="d-sm-flex flex-grow-1 justify-content-end">
    <span className="input-group-text py-0 px-1 fs-6 m-0">
    <button className='myBtn me-1 my-0' title="clear" onClick={clearAll}><ClearAllIcon color="success" fontSize="small" /></button>
    <button className='myBtn me-1 my-0' title="Save Ctrl+Enter" onClick={SaveCard}><SaveAsOutlinedIcon color="success" fontSize="small" /></button>
    </span>
  </div>
</div>

</div>

);


}

export default EditDivision;