import { useState,createContext } from 'react';
import App from './App.js'

const CtxTodoItems = createContext();
const CtxTodoid = createContext();
const CtxPriority = createContext();
const CtxStatus = createContext();

function TodoStates(){
    const [todoItems, setTodoItems] = useState([]);
    const [todoid,setTodoid] = useState(0);
    const [priority, setPriority] = useState('low'); 
    const [status, setStatus] = useState('In-Queue');

    
return(
    <>
        <CtxTodoItems.Provider value={{todoItems, setTodoItems}}>
        <CtxTodoid.Provider value={{todoid,setTodoid}}>
        <CtxPriority.Provider value={{priority, setPriority}}>
        <CtxStatus.Provider value={{status, setStatus}}>
        <App />
        </CtxStatus.Provider>
        </CtxPriority.Provider>
        </CtxTodoid.Provider>
        </CtxTodoItems.Provider>

    </>
);
}

export default TodoStates;
export {CtxTodoItems,CtxTodoid,CtxPriority,CtxStatus};