import './App.css';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import RandomTextGenerator from './RandomTextGenerator';

import Card from './Card';
import { loremIpsum } from 'lorem-ipsum';
import { useState, useEffect, useContext } from 'react';
import EditDivision from './EditDivision.jsx';
import {CtxTodoItems,CtxTodoid,CtxPriority,CtxStatus} from './TodoStates.jsx';


function App() {
 
  const {todoItems, setTodoItems}=useContext(CtxTodoItems);
  const {todoid,setTodoid}=useContext(CtxTodoid);
  const {priority, setPriority}=useContext(CtxPriority);
  const {status, setStatus}=useContext(CtxStatus);

  const [loremText, setLoremText] = useState({
    count: 50,
    units: 'words',
    format: 'plain'
  });

  const makeLoremText = (vCount) => {

    setLoremText({
      count: vCount,
      units: 'words',
      format: 'plain',
    })

    console.log(loremText);

  }

  const getLoremText = loremIpsum(loremText);
  const CardPriority=(props)=>{
    let prtyColor = 'info';
    switch (props.priority) {
      case 'high':
        prtyColor = 'red';
        break;
      case 'medium':
        prtyColor = 'orange';
        break;
      default:
        prtyColor = 'lightgreen';
        break;
    };
    
    return(<SquareRoundedIcon sx={{ color: prtyColor }} fontSize="small" style={{height:10+'px'}}/>);
  }



const fetchData =async ()=> {
  try{
    const res = await fetch(`${window.config.REACT_APP_API_HOST}:${window.config.REACT_APP_API_PORT}/todo/list`);
    const result = await res.json();
    setTodoItems(result);

  } catch(e){
    console.log(e);
  }
}



useEffect(()=>{
    fetchData();
    
},[]);


const FilterTodoItemsByStatus=(props)=>
{
  if(todoItems.length <= 0 ) return(<></>);
  
  console.log(props.status);
  let items=todoItems;
  items=items.filter((item) => item.status === props.status);
  let highItems=items.filter((item) => item.status === props.status && item.priority === 'high');
  let mediumItems=items.filter((item) => item.status === props.status && item.priority === 'medium');
  let lowItems=items.filter((item) => item.status === props.status && item.priority === 'low');

  items=[...highItems,...mediumItems,...lowItems];

  console.log(items);
  return (
    
    items.map((item,index)=>{   
    return(
      <div key={index} className="card my-1 me-2 col-sm-3 mycard" style={{width:22+'rem'}}>
        <Card id={item.id} title={item.title} desc={item.desc} priority={item.priority} status={item.status} 
        CardPriority={CardPriority} />
      </div>
      )})

    
    
  );

}


//resizing the windows
const BORDER_SIZE = 4;
let right_panel;
let separator;
let m_pos;

window.onload = function() {
  console.log("i am here");
  right_panel = document.getElementById("right_panel");
  separator = document.querySelector('.separator');

  separator.addEventListener("mousedown", function(e){
    if (e.offsetX < BORDER_SIZE) {
      console.log("adding listener" + e.offsetX +" " + BORDER_SIZE);
      m_pos = e.x;
      document.addEventListener("mousemove", resize, false);
    }
  }, false);

  right_panel.addEventListener("mouseup", function(){
    document.removeEventListener("mousemove", resize, false);
}, false);
};



function resize(e){
  const dx = m_pos - e.x;
  m_pos = e.x;
  right_panel.style.width = (parseInt(getComputedStyle(right_panel, '').width) + dx) + "px";
};

//--------------------

  return (
    <>

      <div className="container-fluid vh-100 d-sm-flex flex-column p-1 border border-2 border-success">
        <div className="p-0 border border-1 border-success bg-success text-light">
          <font size="7" style={{ fontFamily: "'Red Rose', serif",padding:0+'px',margin:0+'px',lineHeight:40+'px'}}>T<img src='myphoto.png' title="Tapan Palei" width="25px" style={{border:1,borderRadius:10+'px',marginBottom:4+'px',padding:0+'px'}}/>DO</font>
        </div>
        <div className="d-sm-flex flex-grow-1 flex-row p-0 border border-1 border-success ">
          <div id="left_panel" className="d-sm-flex flex-grow-1 flex-column p-1 border border-0 border-success">
            <div>
              <ul className="nav nav-tabs border-bottom border-success" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link border-bottom-0 active py-1" id="home-tab" data-bs-toggle="tab" data-bs-target="#In-Queue-tab-pane" type="button" role="tab" aria-controls="In-Queue-tab-pane" aria-selected="true"><font size="2">InQueue</font></button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link border-bottom-0 py-1" id="profile-tab" data-bs-toggle="tab" data-bs-target="#In-Progress-tab-pane" type="button" role="tab" aria-controls="In-Progress-tab-pane" aria-selected="false"><font size="2">InProgress</font></button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link border-bottom-0 py-1" id="contact-tab" data-bs-toggle="tab" data-bs-target="#In-Waiting-tab-pane" type="button" role="tab" aria-controls="In-Waiting-tab-pane" aria-selected="false"><font size="2">InWaiting</font></button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link border-bottom-0 py-1" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#Completed-tab-pane" type="button" role="tab" aria-controls="Completed-tab-pane" aria-selected="false"><font size="2">Completed</font></button>
                </li>
              </ul>
              
            </div>
            <div className="tab-content" id="myTabContent" style={{ overflowY: 'scroll', height: 82 + 'vh', position: 'relative' }}>
              <div className="tab-pane fade show active" id="In-Queue-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0"><div className="d-sm-flex flex-wrap"><FilterTodoItemsByStatus status='In-Queue' /></div></div>
              <div className="tab-pane fade" id="In-Progress-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0"><div className="d-sm-flex flex-wrap"><FilterTodoItemsByStatus status='In-Progress' /></div></div>
              <div className="tab-pane fade" id="In-Waiting-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0"><div className="d-sm-flex flex-wrap"><FilterTodoItemsByStatus status='In-Waiting' /></div></div>
              <div className="tab-pane fade" id="Completed-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabIndex="0"><div className="d-sm-flex flex-wrap"><FilterTodoItemsByStatus status='Completed' /></div></div>
            </div>

          </div>
          <div id="right_panel" className="d-sm-flex flex-row col-sm-4 p-2 border border-0 border-success">
            <div className="separator me-2"></div>   
            <EditDivision />
          </div>
   
            
          
        </div>



      </div>
    </>

  );
}

export default App;
