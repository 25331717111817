
/**
 * This is created by Tapan Palei
 * 
*/
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import {CtxTodoItems,CtxTodoid,CtxPriority,CtxStatus} from './TodoStates.jsx';
import { useContext } from 'react';
const Card=(props)=> {

  const {todoItems, setTodoItems}=useContext(CtxTodoItems);
  const {todoid,setTodoid}=useContext(CtxTodoid);
  const {priority, setPriority}=useContext(CtxPriority);
  const {status, setStatus}=useContext(CtxStatus);

  const editTodoItem = (theId)=>(event) => {
    if (event.detail !== 2) return;
    //console.log("id="+event.target.value);
    console.log(theId);
    let items=todoItems;
    //items=items.filter((item)=>item.id==event.target.value);
    items=items.filter((item)=>item.id==theId);
    setTodoid(items[0].id);
    //document.getElementById("id").value=items[0].id;
    console.log("todoid = "+document.getElementById("todoid").value);
    document.getElementById("title").value=items[0].title;
    document.getElementById("desc").value=items[0].desc;
    //document.getElementById("priority").value=items[0].priority;
    setPriority(items[0].priority);
    //document.getElementById("status").value=items[0].status;
    setStatus(items[0].status);
    return(<></>);
  }

  const deleteTodoItem = async (event) => {
    let ans=window.confirm("Are you sure to remove?");
    if(!ans) return false;
    let deltId =event.currentTarget.value;
    const cards=todoItems.filter((item)=>item.id == deltId);
    
    try{
      const res = await fetch(`${window.config.REACT_APP_API_HOST}:${window.config.REACT_APP_API_PORT}/todo/remove`,{
      method: "POST",
        headers: {
            "Accept":"application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(cards[0])
      }
    );
      setTodoItems((oldItems)=>{
        return(oldItems.filter((item)=>item.id != deltId));
      });
    } catch(e){
      console.log(e);
    }
  }


    return (
          <div className="card-body flex-row p-0" onClick={editTodoItem(props.id)}>
            <div className="d-md-flex flex-grow-1 flex-column">
              <div className="d-sm-flex px-2">
                <div className="d-sm-flex flex-grow-1"><b>{props.title}</b> <font size="1">({props.id})</font></div>
                <div><props.CardPriority priority={props.priority}/></div>
              </div>
              <div className="ellipsis card-text px-2 justify-content-sm-between fs-6">
                <font size="2">{props.desc.substring(0,100)+'..'}</font>
              </div>
              <div className="d-sm-flex justify-content-end">
                <button id="btnDelete" value={props.id} className='myBtn px-0 py-0' title="Delete Todo Item" onClick={deleteTodoItem}><DeleteForeverIcon id="delt" color="action" fontSize="small" /></button>
              </div>
            </div>
          </div>
    );
  }

  export default Card;